<template>
  <div id="mrf-report">
    <v-overlay :value="OverlayLoadingFlag">
      <div class="text-center loading-text">
        <p>{{ OverlayLoadingText }}</p>
        <p></p>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>
      <v-dialog v-model="scoreRecordPrompt" persistent max-width="75%">
        <lom-events-preview
          pageTitle="About The Events"
          pageDescription="Validate 100% efficiency events"
          :scoreRecordPrompt="scoreRecordPrompt"
          @hideScoreRecordPrompt="hideScoreRecordPrompt"
          :recordData="selectedData"
          v-if="scoreRecordPrompt"
        ></lom-events-preview>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Area</h6>
                </label>
                <v-autocomplete
                  @change="getZoneCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  :rules="AreaCodeRules"
                  v-model="AreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  @change="getRegionCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Region
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  :rules="RegionCodeRules"
                  v-model="RegionCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    Lom
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Portfolio</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="PortfolioId"
                  :rules="PortfolioIdRules"
                  :items="PortfolioIdOptions"
                  :loading="PortfolioIdOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                >
                </v-autocomplete>
              </v-col>
              </v-col>
                 <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Event Category</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="EventCategoryId"
                    :rules="EventCategoryIdRules"
                    :items="EventCategoryIdOptions"
                    :loading="EventCategoryIdOptionsLoading"
                    dense
                    clearable
                    outlined
                    multiple
                    chips
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label
                  ><h6><span class="text-danger">*</span> From Date</h6></label
                >
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="FromDate"
                      label="From Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="FromDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label
                  ><h6><span class="text-danger">*</span> To Date</h6></label
                >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="ToDate"
                      label="To Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ToDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-row wrap v-if="ResultFlag">
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData1.length }} Records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="ExcelDownloadFlag"
                color="info"
                @click.prevent="
                  generateExcel(
                    tableData1,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="mr-4"
                >Download</v-btn
              >
            </v-col>
            <p></p>
          </v-row>
          <br />
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:[`item.FilePreviewFlag`]="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.FilePreviewFlag && item.FilePath != ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.FilePath != '' && item.FilePreviewFlag == 1"
                        @click="previewData(item, e)"
                        target="_blank"
                        color="blue"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-search</v-icon>Preview
                      </v-btn>
                    </template>
                    <span>{{ item.MonthName }} MRF Preview</span>
                  </v-tooltip>
                  <br />
                  <small>{{ item.LastUpdatedAtTxt }}</small>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="scoreData(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import EventsScorePreview from "@/view/pages/erp/events-score/EventsScorePreview.vue";
import LomEventsPreview from "@/view/pages/erp/reports/LomEventsPreview.vue";

export default {
  mixins: [common],
  components: {
    EventsScorePreview,
    LomEventsPreview,
  },
  data() {
    return {
      LoadingFlag: false,
      uploadPercentage: 0,
      previewRecordPrompt: false,

      ResultFlag: false,

      PageTitle: "",
      PageDescription: "",

      alert: {},
      selected: {},

      valid1: true,
      OverlayLoadingFlag: false,
      OverlayLoadingText: "",

      JciYearCode: "",

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      PortfolioIdRules: [(v) => !!v || "Portfolio is required"],
      PortfolioId: "",
      PortfolioIdOptions: [],
      PortfolioIdOptionsLoading: false,

      EventCategoryIdRules: [(v) => !!v || "Event Category is required"],
      EventCategoryId: "",
      EventCategoryIdOptions: [],
      EventCategoryIdOptionsLoading: false,

      ExcelDownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},
      scoreRecordPrompt: false,

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,
      RoleId: "",

      PageInfo: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var currentDate = this.getCurrentDate();
        this.FromDate = currentDate;
        this.ToDate = currentDate;

        this.pageData();
      }
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    PortfolioIdOptions: function () {
      console.log("watch PortfolioIdOptions");
      this.PortfolioIdOptionsLoading = false;
    },
    PortfolioId: function () {
      console.log("watch PortfolioId");
      this.EventCategoryId = "";
    },
    EventCategoryIdOptions: function () {
      console.log("watch EventCategoryIdOptions");
      this.EventCategoryIdOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "np_report",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getAreaCodeOptions();
      this.getPortfolioIdOptions();
      this.getEventCategoryIdOptions();
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        YearCode: this.JciYearCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    hideScoreRecordPrompt() {
      console.log("hideScoreRecordPrompt called");
      this.scoreRecordPrompt = false;
      this.searchForm();
    },
    scoreData(tr) {
      console.log("scoreData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.scoreRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.tableData1 = [];
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.ZoneCodeOptions = [];
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 4,
          AreaCode: areaCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.tableData1 = [];
      this.RegionCode = "";
      this.LomCode = "";
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "" && RegionCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          RegionCode: RegionCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (RegionCode == "") {
          message += "Region should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getPortfolioIdOptions() {
      console.log("getPortfolioIdOptions called");
      this.LoadingFlag = true;
      this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "PortfolioId";
      var selectbox1_destination = "PortfolioIdOptions";
      var selectbox1_url = "api/portfolio/options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getEventCategoryIdOptions() {
      console.log("getEventCategoryIdOptions called");
      this.EventCategoryIdOptionsLoading = true;
      var selectbox1_source = "EventCategoryId";
      var selectbox1_destination = "EventCategoryIdOptions";
      var selectbox1_url = "api/jci-event-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var message = "";

        var YearCode = this.JciYearCode;
        console.log({ YearCode });

        var AreaCode = this.AreaCode;
        console.log({ AreaCode });

        var ZoneCode = this.ZoneCode;
        console.log({ ZoneCode });

        var RegionCode = this.RegionCode;
        console.log({ RegionCode });

        var LomCode = this.LomCode;
        console.log({ LomCode });

        if (YearCode != "" && ZoneCode != "") {
          this.LoadingFlag = true;
          this.ResultFlag = false;
          this.tableData1 = [];
          this.ExcelDownloadFlag = false;
          this.ExcelFileFlag = false;
          this.Modules = {};

          var server_url = companyConfig.apiURL;
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;
          var add_url = server_url + "api/jci-events/lists";
          var upload = {
            UserInterface: 6,
            Year: YearCode,
            Zone: this.ZoneCode,
            Lom: LomCode,
            FromDate: this.FromDate,
            ToDate: this.ToDate,
            Portfolio: this.PortfolioId,
            EventCategory: this.EventCategoryId,
          };
          console.log("upload=" + JSON.stringify(upload));
          console.log(
            "server_url=" +
              server_url +
              ", token=" +
              token +
              ", add_url=" +
              add_url
          );

          const thisIns = this;
          var output = "";
          var records = {};
          var flag = 0;

          this.$http({
            url: add_url,
            method: "POST",
            data: upload,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then(function (response) {
              console.log("response=" + JSON.stringify(response));

              thisIns.ResultFlag = true;
              thisIns.LoadingFlag = false;

              output = response.data.output;
              flag = response.data.flag;
              records = response.data.records;
              console.log("output=" + output + ", flag=" + flag);

              console.log({ records });

              if (flag == 1) {
                var options = records.TableOptions;
                console.log({ options });

                thisIns.tableColumns1 = records.TableHeader;
                thisIns.tableData1 = records.TableData;
                thisIns.tableOptions1 = options;

                thisIns.ExcelDownloadFlag = options.ExcelDownloadButtonFlag;
                thisIns.ExcelFileName = options.ExcelFileName;
                thisIns.ExcelFields = options.JsonExcelFields;

                thisIns.toast("success", output);
              } else {
                thisIns.toast("error", output);
              }
            })
            .catch(function (error) {
              console.log("error=" + error);
              thisIns.LoadingFlag = false;
            });
        } else {
          if (YearCode == "") {
            message += "Year should not be empty. ";
          }
          if (LomCode == "") {
            message += "Month should not be empty. ";
          }
          this.toast("error", message);
        }
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#mrf-report {
  .loading-text {
    font-size: 18px;
  }
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>